import vue from 'vue';
import { Button,Icon,NavBar , Tabbar,Cell,PullRefresh , TabbarItem,List , Tab, Tabs, Form, Field, Popover, Search, Switch, Tag, Calendar, Overlay, Uploader, DropdownMenu, DropdownItem, Popup, Picker, DatetimePicker,RadioGroup, Radio,Checkbox, CheckboxGroup,ActionSheet,Grid, GridItem,Toast } from 'vant';
vue.use(Button)
vue.use(Icon)
vue.use(Cell)
vue.use(NavBar)
vue.use(PullRefresh)
vue.use(List)
vue.use(Tabbar)
vue.use(TabbarItem)
vue.use(Tab)
vue.use(Tabs)
vue.use(Form)
vue.use(Field)
vue.use(Popover)
vue.use(Search)
vue.use(Switch)
vue.use(Tag)
vue.use(Calendar)
vue.use(Overlay)
vue.use(Uploader)
vue.use(DropdownMenu)
vue.use(DropdownItem)
vue.use(Popup)
vue.use(Picker)
vue.use(DatetimePicker)
vue.use(RadioGroup)
vue.use(Radio)
vue.use(Checkbox)
vue.use(CheckboxGroup) 
vue.use(ActionSheet)
vue.use(Grid) 
vue.use(GridItem)
vue.use(Toast)