export function getToken(){
    return window.localStorage.getItem('token')
}
export function setToken(token){
    return window.localStorage.setItem('token',token)
}
export function removeToken(){
    return window.localStorage.removeItem('token')
}
export function getTimeOut(){
    return window.localStorage.getItem('timeOut')
}
export function setTimeOut(){
     window.localStorage.setItem('timeOut',Date.now())
}
export function removeTimeOut(){
    return window.localStorage.removeItem('timeOut')
}
export function getUserInfo(){
    return JSON.parse(window.localStorage.getItem('userInfo'))
}
export function setUserInfo(userInfo){
    window.localStorage.setItem('userInfo',JSON.stringify(userInfo))
}
export function removeUserInfo(){
    return window.localStorage.removeItem('userInfo')
}
export function getDepartmentCode(){
    return window.localStorage.getItem('departmentCode')
}
export function setDepartmentCode(departmentCode){
    return window.localStorage.setItem('departmentCode',departmentCode)
}
export function removeDepartmentCode(){
    return window.localStorage.removeItem('departmentCode')
}
export function getWorkID(){
    return window.localStorage.getItem('workId')
}
export function setWorkId(workId){
    return window.localStorage.setItem('workId',workId)
}
export function removeWorkId(){
    return window.localStorage.removeItem('workId')
}
export function getWorkIngId(){
    return window.localStorage.getItem('workIngId')
}
export function setWorkIngId(workIngId){
    return window.localStorage.setItem('workIngId',workIngId)
}
export function removeWorkIngId(){
    return window.localStorage.removeItem('workIngId')
}
export function getNewProcedureList(){
    return JSON.parse(window.localStorage.getItem('newProcedureList'))
}
export function setNewProcedureList(newProcedureList){
    return window.localStorage.setItem('newProcedureList',JSON.stringify(newProcedureList))
}
export function removeNewProcedureList(){
    return window.localStorage.removeItem('newProcedureList')
}
