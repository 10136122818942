import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible/index.js'
import '@/common/css/commons.css'
import '@/common/js/vant'
import 'vant/lib/icon/local.less';
import '@/common/css/iconfont.css'
import '@/permission.js'
import * as echarts from 'echarts';
import 'vant/lib/index.less'
import { Dialog } from 'vant';
import { Col, Row } from 'vant';
import { Collapse, CollapseItem } from 'vant';
import { Step, Steps } from 'vant';
// import { Icon } from 'vant';
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.use(Dialog)
Vue.use(Col)
Vue.use(Row)
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Step);
Vue.use(Steps);
// Vue.use(Icon);
Vue.filter('dateFormat', function (date) {
  if (date != undefined){
    var nerDate = date.substring(0, 10);
    return nerDate
  }else{
    return
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
