import {getWorkID,setWorkId,removeWorkId,getWorkIngId,setWorkIngId,removeWorkIngId} from '@/utils/auth.js'
const state = {
    workId:getWorkID(),
    workIngId:getWorkIngId(),
}
const mutations = {
    shareWorkId(state,id){
        state.workId = id
    },
    closeWorkId(state){
        state.workId = ''
    },
    shareWorkIngId(state,id){
        state.workIngId = id
    },
    closeWorkIngId(state){
        state.workIngId = ''
    }
}
const actions = {
    getShareWorkId(context,id){
        context.commit('shareWorkId',id)
        setWorkId(id)
    },
    closeWorkId(context){
        context.commit('closeWorkId');
        removeWorkId()
    },
    getShareWorkIngId(context,id){
        context.commit('shareWorkIngId',id)
        setWorkIngId(id)
    },
    closeWorkIngId(context){
        context.commit('closeWorkIngId');
        removeWorkIngId()
    },
}
export default {
    namespaced:true,
    state,
    mutations,
    actions
}