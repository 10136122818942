import axios from 'axios'
import store from '@/store'
import {Toast} from 'vant';
import {getTimeOut} from '@/utils/auth.js'
import router from '../router';
//设置token过期时间
const TimeOut = 3600;
const service = new axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    // baseURL: 'http://192.168.10.31:8899/api',
    // baseURL: 'http://192.168.10.159:8899/api',
    // baseURL: 'http://192.168.10.168:8899',
    timeout: 50000
})
service.interceptors.request.use(config => {
        if (store.getters.token) {
            if (isChangeTimeout()) {
                store.dispatch('user/bowOut')
                router.push('/login')
                return Promise.reject(new Error('token超时了'))
            }
            config.headers.token = store.getters.token
        }
        return config
    }, error => {
        console.log(err);
        return Promise.reject(err);
    }
)
service.interceptors.response.use(response => {
    // if(response.config.url.indexOf('/inf/wx/get_signature') !== -1){
    //     console.log('response..inf............', JSON.stringify(response.data))
    //     return response.data
    // }else{
    //     console.log('bbb......')
    const {code, data, message} = response.data;
    if (code == 200) {
        return data
    } else if (!code){
        return response
    }else {
        Toast.fail(message)
        return Promise.reject(new Error(message))
    }
    // }
})

function isChangeTimeout() {
    var currentTime = Date.now();
    var oldTime = getTimeOut();
    return (currentTime - oldTime) / 1000 > TimeOut
}

export default service
