import request  from "@/utils/request";
export function getImageCode(){
    return request({
        url:'verificationCode/getBase64Image'
    })
}
export function login(data){
    return request({
        url:'login',
        method:'post',
        data
    })
}
export function updatePassword(data){
    return request({
        url:'/sysUser/updatePassword',
        method:'post',
        data
    })
}