import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: () => import('@/pages/login/index')
  },
  {
    path: '/',
    redirect:'/index',
    component: () => import('@/pages/layout/index'),
    children: [
      {
        path: '/index',
        component: () => import('@/pages/index/index'),
      },
      {
        path: '/board',
        component: () => import('@/pages/board/index'),
      },{
        path: '/task',
        component: () => import('@/pages/task/index'),
      },{
        path: '/user',
        component: () => import('@/pages/user/index'),
      }
    ]
  },
  {
    path: '/workDetails',
    name:'/workDetails',
    component: () => import('@/pages/workDetails/index')
  },
  {
    path: '/reportWork',
    name:'/reportWork',
    component: () => import('@/pages/reportWork/index')
  },
  {
    path: '/createWork',
    name:'/createWork',
    component: () => import('@/pages/createWork/index')
  },
  {
    path: '/search',
    name:'/search',
    component: () => import('@/pages/index/components/search/index')
  },
  {
    path: '/selectProduct',
    name:'/selectProduct',
    component: () => import('@/pages/selectProduct/index')
  },
  {
    path: '/addProduct',
    name:'/addProduct',
    component: () => import('@/pages/addProduct/index')
  },
  {
    path: '/craft',
    name:'/craft',
    component: () => import('@/pages/craft/index')
  },
  {
    path: '/procedure',
    name:'/procedure',
    component: () => import('@/pages/procedure/index')
  },
  {
    path: '/modify',
    name:'/modify',
    component: () => import('@/pages/modify/index')
  },
  {
    path: '/mould',
    name:'/mould',
    component: () => import('@/pages/mould/index')
  },
  {
    path: '/production',
    name:'/production',
    component: () => import('@/pages/production/index')
  },
  {
    path: '/reportWork1',
    name:'/reportWork1',
    component: () => import('@/pages/reportWork1/index')
  },
  {
    path: '/job',
    name:'/job',
    component: () => import('@/pages/job/index')
  },
  {
    path: '/output',
    name:'/output',
    component: () => import('@/pages/output/index')
  },
  {
    path: '/unqualified',
    name:'/unqualified',
    component: () => import('@/pages/unqualified/index')
  },
  {
    path:'/updatePassWord',
    name:'/updatePassWord',
    component: () => import('@/pages/updatePassWord/index')
  },
  {
    path:'/approval',
    name:'approval',
    component: () => import('@/pages/approval/index')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
