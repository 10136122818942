import router from '@/router'
import store from '@/store'
router.beforeEach((to, from, next) => {
    if (store.getters.token) {
        if(to.path === '/login'){
            next({
                path:'/'
            })
        }else{
            next();
        }
    } else {
        if(to.path === '/login' || to.path === '/approval') next()
        else next({path:'/login'})
    }
})