import Vue from 'vue'
import Vuex from 'vuex'
import getters from '@/store/getters'
import user from '@/store/user'
import work from '@/store/work'
import procedure from '@/store/procedure'
Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  modules: {
    user,
    work,
    procedure
  }
})
