import { getNewProcedureList, setNewProcedureList, removeNewProcedureList } from '@/utils/auth.js'
const state = {
    newProcedureList: getNewProcedureList()
}
const mutations = {
    setProcedureList(state, list) {
        const result = getNewProcedureList() || []
        const procedureList = [...result, ...list]
        state.newProcedureList = procedureList;
        setNewProcedureList(procedureList)
    },
    removeProcedureList(state) {
        state.newProcedureList = []
    }
}
const actions = {
    setNewProcedureList(context, list) {
        context.commit('setProcedureList', list);
    },
    removeNewProcedureList(context) {
        context.commit('removeProcedureList')
        removeNewProcedureList()
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}