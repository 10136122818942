import {setToken,getToken,getUserInfo,setUserInfo,getDepartmentCode,setDepartmentCode,setTimeOut,removeToken,removeTimeOut,removeUserInfo,removeDepartmentCode} from '@/utils/auth.js'
import {login} from '@/api/login.js'
const state = {
    token:getToken(),
    userInfo:getUserInfo(),
    departmentCode:getDepartmentCode()
}
const mutations = {
    reviseLogin(state,list){
        state.token = list.token;
        state.userInfo = list.loginSysUserVo;
        state.departmentCode = list.loginSysUserVo.departmentCode;
        setToken(list.token);
        setUserInfo(list.loginSysUserVo);
        setDepartmentCode(list.loginSysUserVo.departmentCode)
    },
    layout(state){
        state.token = '';
        state.userInfo = [];
        state.departmentCode = '';
        removeToken();
        removeTimeOut();
        removeUserInfo();
        removeDepartmentCode();
    }
}
const actions = {
    async login(context,data){
        const res = await login(data);
        var list = {
            token:res.token,
            loginSysUserVo:res.loginSysUserVo
        }
        setTimeOut();
        context.commit('reviseLogin',list);
    },
    bowOut(context){
        context.commit('layout')
    }
}
export default {
    namespaced:true,
    state,
    mutations,
    actions
}
